.snowflake {
	position: absolute;
	border-radius: 1000px;
	z-index: 1;
	animation: fall linear forwards;
}

@keyframes fall {
	0% {
		top: -40px;
	}
	100% {
		top: 100%;
	}
}
